import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';

import * as Turbo from '@hotwired/turbo';

Turbo.start();
Turbo.setFormMode("optin");

import TurboPower from 'turbo_power'

TurboPower.initialize(Turbo.StreamActions)

import * as ActiveStorage from '@rails/activestorage';

ActiveStorage.start();

import '@hotwired/turbo-rails';

import '../controllers';

import './application.scss';

import Alpine from 'alpinejs';
// @ts-ignore
import intersect from '@alpinejs/intersect';

window.Alpine = Alpine

Alpine.plugin(intersect)
Alpine.start()

if (navigator.userAgent.match(/samsung/i)) {
  alert("Your browser (Samsung Internet) may not show this website " +
    "correctly. Please consider using a standards-compliant " +
    "browser instead. \n\n" +
    "We recommend Apple Safari or Google Chrome.");
}